<template>
    <vx-card title="E-Mail Templates">
        <vs-button color="primary" size="small" @click="onClickNew" class="absolute right-4 -top-8">Neues Template erstellen</vs-button>
        <vs-list>
            <vs-list-item v-for="template in templates" :subtitle="template.name" :title="template.subject">
                <vs-button color="primary" type="border" size="small" @click="onClickSelect(template)">Bearbeiten</vs-button>
            </vs-list-item>
        </vs-list>
    </vx-card>
</template>

<script>
    export default {
        name: "TemplateList",
        props: {
            templates: {
                type: Array,
                default: () => {
                    return [];
                }
            }
        },
        methods: {
            onClickSelect(template) {
                this.$emit('select', template);
            },
            onClickNew() {
                this.$emit('select', {});
            }
        }
    }
</script>

<style scoped>

</style>