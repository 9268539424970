<template>
    <div class="vx-row page-mail-templates">
        <div class="vx-col md:w-1/3 w-full mb-1">
            <template-list :templates="templates" @select="onSelectTemplate"></template-list>
        </div>
        <div class="vx-col md:w-2/3 w-full mb-1">
            <template-editor :template="selectedTemplate" @saved="fetchData"></template-editor>
        </div>
    </div>
</template>

<script>
    import TemplateList from "./components/TemplateList";
    import TemplateEditor from "./components/TemplateEditor";
    import ApiService from "../../../api";

    export default {
        name: "Templates",
        components: {TemplateEditor, TemplateList},
        data() {
            return {
                templates: [],
                selectedTemplate: null,
            }
        },
        mounted() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.$vs.loading();

                ApiService.get('mail-templates')
                    .then((response) => {
                        if (response.data.status !== 'success') {
                            return false;
                        }

                        this.templates = response.data.result;
                    })
                    .catch((error) => {
                    })
                    .finally(() => {
                        this.$vs.loading.close();
                    });
            },
            onSelectTemplate(template) {
                this.selectedTemplate = template;
            },
        }
    }
</script>