<template>
    <div class="mail-template--editor">
        <template v-if="data !== null">
            <vx-card title="Template Einstellungen">
                <div class="flex mb-4">
                    <div class="pr-2 w-1/2">
                        <vs-input label="Technische Bezeichnung" v-model="data.name" class="w-full" name="name" v-validate="'required'"/>
                        <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first('name') }}</span>
                    </div>
                    <div class="pl-2 w-1/2">
                        <vs-input label="Interner Matchcode / Beschreibung" v-model="data.matchcode" class="w-full" name="matchcode" v-validate="'required'"/>
                        <span class="text-danger text-sm" v-show="errors.has('matchcode')">{{ errors.first('matchcode') }}</span>
                    </div>
                </div>
                <div class="flex mb-4">
                    <div class="pr-2 w-1/2">
                        <vs-input label="Absender Name" v-model="data.from_name" class="w-full" name="from_name" v-validate="'required'"/>
                        <span class="text-danger text-sm" v-show="errors.has('from_name')">{{ errors.first('from_name') }}</span>
                    </div>
                    <div class="pl-2 w-1/2">

                    </div>
                </div>
                <div class="flex mb-4">
                    <div class="pr-2 w-1/2">
                        <vs-input label="Betreff" v-model="data.subject" class="w-full" name="subject" v-validate="'required'"/>
                        <span class="text-danger text-sm" v-show="errors.has('subject')">{{ errors.first('subject') }}</span>
                    </div>
                    <div class="pl-2 w-1/2">
                        <vs-input label="Absender Email" v-model="data.from_email" class="w-full" name="from_email" v-validate="'required'"/>
                        <span class="text-danger text-sm" v-show="errors.has('from_email')">{{ errors.first('from_email') }}</span>
                    </div>
                </div>
                <div class="flex mb-4">
                    <div class="pr-2 w-1/2">
                        <vs-input label="CC" v-model="data.preset_cc" class="w-full" name="preset_cc" />
                    </div>
                    <div class="pl-2 w-1/2">
                        <vs-input label="BCC" v-model="data.preset_bcc" class="w-full" name="preset_bcc"/>
                    </div>
                </div>
                <div class="flex mb-4">
                    <div class="pr-2 w-1/2">
                        <vs-input label="Template Vorlage" v-model="data.template_file" class="w-full" name="template_file" v-validate="'required'"/>
                        <span class="text-danger text-sm" v-show="errors.has('template_file')">{{ errors.first('template_file') }}</span>
                    </div>
                    <div class="pl-2 w-1/2 pt-5">
                        <category-selector v-model="data.categories" type="mail_templates" ></category-selector>
                    </div>
                </div>
                <div class="flex mb-4">
                    <div class="w-1/2">
                        <vs-checkbox v-model="data.is_html">E-Mail als HTML versenden</vs-checkbox>
                    </div>
                </div>

                <p v-show="data.is_html" class="text-sm mb-2">HTML Content</p>
                <p v-show="!data.is_html" class="text-sm mb-2">Plaintext Content</p>

                <ace-editor v-show="data.is_html" v-model="data.html_content" @init="editorInit" lang="php_laravel_blade" width="100%" height="300px" theme="tomorrow_night_bright"></ace-editor>
                <ace-editor v-show="!data.is_html" v-model="data.plain_content" @init="editorInit" lang="php_laravel_blade" width="100%" height="300px" theme="tomorrow_night_bright"></ace-editor>

                <div class="flex mt-4">
                    <vs-button @click="sidebarActive=!sidebarActive" color="primary" type="border">Template Kontext</vs-button>
                     <vs-button @click="onClickSave" class="ml-auto">Speichern</vs-button>
                </div>

                <vs-sidebar parent="body"  position-right default-index="1" color="primary" class="half-vs-sidebar items-no-padding" v-model="sidebarActive">
                    <div class="customizer-header mt-6 flex items-center justify-between px-6">
                        <div>
                            <h4>Template Kontext</h4>
                        </div>
                        <feather-icon icon="XIcon" @click.stop="sidebarActive = false" class="cursor-pointer"></feather-icon>
                    </div>
                    <vs-divider class="mb-0" />

                    <VuePerfectScrollbar class="scroll-area--customizer" :settings="settings">
                            <tree-view :data="JSON.parse(data.context)" :options="{maxDepth: 1}" class="p-2"></tree-view>
                    </VuePerfectScrollbar>
                </vs-sidebar>

            </vx-card>
        </template>
    </div>
</template>

<script>
    import ApiService from "../../../../api";
    import VuePerfectScrollbar from 'vue-perfect-scrollbar';
    import CategorySelector from "@/components/search/CategorySelector.vue";

    export default {
        name: "TemplateEditor",
        components: {
            CategorySelector,
            VuePerfectScrollbar
        },
        props: {
            template: {
                type: Object,
                default: () => {
                    return null
                }
            }
        },
        data() {
            return {
                data: null,
                editorOptions: {
                    showGutter: true
                },
                sidebarActive: false,
                settings: {
                    maxScrollbarLength : 60,
                    wheelSpeed         : .60,
                },
            }
        },
        mounted() {
            this.data = this.template;
        },
        methods: {
            editorInit (editor) {
                require('ace-builds/src-min-noconflict/ext-language_tools');
                require('ace-builds/src-min-noconflict/mode-php_laravel_blade');
                require('ace-builds/src-min-noconflict/theme-tomorrow_night_bright');
                editor.session.setMode("ace/mode/php");
                editor.setOptions(this.editorOptions);
            },
            onChangeTemplate(value) {
                if(value.html_content === null || value.html_content === undefined) {
                    value.html_content = '';
                }
                if(value.plain_content === null || value.plain_content === undefined) {
                    value.plain_content = '';
                }

                if(value.template_file === null || value.template_file === undefined) {
                    value.template_file = 'default';
                }

                this.data = value;
            },
            onClickSave() {
                this.$validator.validateAll().then(result => {
                    if(result) {
                        if(this.data.id) {
                            this.updateTemplate();
                        } else {
                            this.createTemplate();
                        }
                    }
                })
            },

            createTemplate() {
                this.$vs.loading();

                ApiService.post('mail-templates', this.data).then((response) => {
                    if (response.data.status !== 'success') {
                        return false;
                    }
                    this.$vs.notify({
                        title: 'Erfolgreich',
                        text: 'Das Template wurde erfolgreich angelegt',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'success'
                    })

                    this.$emit('saved');
                }).catch((error) => {
                    let errorMessage = error.message;

                    if(error.response.data.message) {
                       errorMessage = error.response.data.message;
                    }

                    this.$vs.notify({
                        title: 'Error',
                        text: errorMessage,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                }).finally(() => {
                    this.$vs.loading.close();
                });
            },

            updateTemplate() {
                ApiService.put('mail-templates/' + this.data.id, this.data).then((response) => {
                    if (response.data.status !== 'success') {
                        return false;
                    }
                    this.$vs.notify({
                        title: 'Erfolgreich',
                        text: 'Ihr Template wurde erfolgreich bearbeitet',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'success'
                    })
                    this.$emit('saved');

                }).catch((error) => {
                    let errorMessage = error.message;

                    if(error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }

                    this.$vs.notify({
                        title: 'Error',
                        text: errorMessage,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                }).finally(() => {
                    this.$vs.loading.close();
                });
            }
        },
        watch: {
            template: 'onChangeTemplate'
        }
    }
</script>

<style lang="scss">
    // Create full width sidebar
    .half-vs-sidebar {
        .vs-sidebar {
            max-width: calc(35% - 260px);
            margin-left: 260px;
        }
    }

    @media only screen and (max-width: 992px) {
        .half-vs-sidebar {
            .vs-sidebar {
                max-width: 100%;
                margin-left: 0;
            }
        }
    }
</style>